import withPageAuthRequiredWrap from '@libs/withPageAuthRequiredWrap';
import Router from 'next/router';
import { useEffect } from 'react';

const IndexPage = (): JSX.Element => {
  // DEMO
  useEffect(() => {
    Router.push('/dashboard');
    return;
  }, []);

  return <div></div>;
};

export const getServerSideProps = withPageAuthRequiredWrap(['common']);

export default IndexPage;
